<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'active'">
  <div class="container-fluid">
    <div>
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
         (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="nav navbar-nav navbar-center">
        <li class="nav-item">
          <a href="/btsl/dashboard/data-view">
            <img class="logo" src="assets/images/logo-full.png">
          </a>
        </li>
        <li *ngIf="surveyType=='P2_SURVEY'" style="padding-left: 30px" class="nav-item">
          <a [class]="navLink('/btsl/dashboard/data-view/welcome')" routerLink="/btsl/dashboard/data-view/welcome" >Welkom</a>
        </li>
        <li *ngIf="surveyType=='P2_SURVEY'" style="padding-left: 30px" class="nav-item">
          <a [class]="navLink('/btsl/dashboard/data-view/welcome-description')" routerLink="/btsl/dashboard/data-view/welcome-description" >Bespreekpunten</a>
        </li>
        <li *ngIf="surveyType=='P2_SURVEY'" style="padding-left: 30px" class="nav-item">
          <a [class]="navLink('/btsl/dashboard/data-view/overview')" routerLink="/btsl/dashboard/data-view/overview" >Overview</a>
        </li>
        <li *ngIf="surveyType=='P2_SURVEY'" style="padding-left: 30px" class="nav-item">
          <a [class]="navLink('/btsl/dashboard/data-view/school')" routerLink="/btsl/dashboard/data-view/school" >{{tagTypes[2]}}</a>
        </li>
        <li *ngIf="surveyType=='P2_SURVEY'" style="padding-left: 30px">
          <a [class]="navLink('/btsl/dashboard/data-view/schoolbestuur')" routerLink="/btsl/dashboard/data-view/schoolbestuur" >{{tagTypes[1]}}</a>
        </li>
        <li *ngIf="surveyType=='P2_SURVEY'" style="padding-left: 30px">
          <a [class]="navLink('/btsl/dashboard/data-view/kantell-grid')" routerLink="/btsl/dashboard/data-view/kantell-grid" >Kantell-Grid</a>
        </li>
        <li *ngIf="surveyType=='P2_SURVEY'" style="padding-left: 30px">
          <a [class]="navLink('/btsl/dashboard/data-view/kantell-benchmark')" routerLink="/btsl/dashboard/data-view/kantell-benchmark" >Kantell-Benchmark</a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li *ngIf="printData?.hasPrint" >
          <button mat-icon-button (click)="printComponent(printData.printBlockName)">
            <mat-icon>print</mat-icon>
          </button>
          <!--<button mat-icon-button printSectionId="printableArea" ngxPrint>
            <mat-icon>print</mat-icon>
          </button>-->
        </li>
        <li *ngIf="surveys.length > 1" >
          <select
            [(ngModel)]="selectedSurvey"
            (ngModelChange)="reloadSurvey(selectedSurvey)"
          >
            <option [selected]="true" value="NULL">--Select Survey--</option>
            <option
              *ngFor="let option of surveys"
              [ngValue]="option">{{shortenName(option.name)}}</option>
          </select>
        </li>
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <li>
          <button mat-icon-button *ngIf="isAuthenticated" (click)="logout()" class=" nav-notification-icons">
            Logout
          </button>
        </li>

      </ul>
    </div>
  </div>
</nav>
